import {
  Circle,
  LabelsContainer,
  Line,
  MobileSubStepperContainer,
  SubStep,
  SubStepTitle,
  SubStepValue,
  SubStepperInnerContainer,
  TimelineWrapper,
} from './styles';
import { FunctionWithProp } from '../../utils/common.model';
import { Timeline } from '../../pages/timeline/timeline.model';
import useHorizontalScrollVisibility from '../../hooks/useHorizontalScrollVisibility';
import { getSelectedFacetValue } from '../../utils/timelineHelper';
import { CLASSES_LIST } from '../../utils/constants';
import { BlurLeft, BlurRight } from '../../utils/styles';

const MobileSubStepper: React.FC<{
  data: Timeline[];
  currentSubStep: number;
  goToClickedSubStep: FunctionWithProp<number>;
}> = ({ data = [], currentSubStep, goToClickedSubStep }) => {
  const {
    innerContainerRef,
    isBlurLeftVisible,
    isBlurRightVisible,
    handleOnScroll,
  } = useHorizontalScrollVisibility(4);

  return (
    <MobileSubStepperContainer>
      <SubStepperInnerContainer
        onScroll={handleOnScroll}
        ref={innerContainerRef}
      >
        {data.map((item: Timeline, index: number) => {
          const { projectedValues = [], label = '' } = item;
          const selectedValue = getSelectedFacetValue(projectedValues);
          const isCurrentStep = index === currentSubStep;
          const currentStepClass = isCurrentStep ? CLASSES_LIST.CURRENT : '';
          const handleOnClick = () => {
            goToClickedSubStep(index);
          };

          return (
            <SubStep key={label} className={currentStepClass}>
              <TimelineWrapper>
                <Circle
                  className={currentStepClass}
                  onClick={handleOnClick}
                ></Circle>
                {index === data.length - 1 ? null : <Line></Line>}
              </TimelineWrapper>
              <LabelsContainer onClick={handleOnClick}>
                <SubStepTitle className={currentStepClass}>
                  {label}
                </SubStepTitle>
                <SubStepValue className={currentStepClass}>
                  {selectedValue}
                </SubStepValue>
              </LabelsContainer>
            </SubStep>
          );
        })}
      </SubStepperInnerContainer>
      {isBlurLeftVisible && <BlurLeft />}
      {isBlurRightVisible && <BlurRight />}
    </MobileSubStepperContainer>
  );
};

export default MobileSubStepper;
