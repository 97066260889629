import Cookies from 'js-cookie';
import {
  APP_URL_QUERY_PARAMS,
  BUSINESS_UNITS,
  DEFAULT_IFRAME_HEIGHT,
  ENVS,
  FALABELLA_COOKIES,
  LANGUAGES,
  PORTUGUESE_COUNTRIES,
  POST_MESSAGE_TYPES,
  PRICE_TYPES,
  PRICE_TYPES_FA,
  SODIMAC_COOKIES,
} from './constants';
import {
  SHORT_BUSINESS_UNITS,
  Environments,
  IMAGE_HOSTS,
  defaultContentsToInclude,
  TENANT_NAMES,
  CONTENT_STACK_HOSTS,
  THUMBNAIL_PLACEHOLDER,
  DESKTOP_MAIN_IMAGE_PLACEHOLDER,
  MOBILE_MAIN_IMAGE_PLACEHOLDER,
} from './constants/urls';
import { Tenants } from './common.model';

export const resizeObserverHandler = (entries: any) => {
  for (const entry of entries) {
    const pageHeight = entry.target?.offsetHeight || null;
    let finalHeight;

    if (!pageHeight || pageHeight < 120) {
      finalHeight = DEFAULT_IFRAME_HEIGHT;
    } else {
      finalHeight = pageHeight;
    }

    window.parent.postMessage(
      {
        iframeHeight: `${finalHeight}px`,
        type: POST_MESSAGE_TYPES.SET_IFRAME_HEIGHT,
      },
      '*'
    );
  }
};

export const updateImageUrlHost = (url: string) => {
  if (!url) return '';
  const urlObject = new URL(url);
  const tenant = getCurrentTenant();
  const country = (tenant.substring(0, 2).toUpperCase() as 'SO' | 'FA') || 'SO';
  const currentHost = urlObject.host;

  if (CONTENT_STACK_HOSTS.includes(currentHost)) {
    urlObject.host = IMAGE_HOSTS[country];
  }

  return urlObject.toString();
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const getMobileAppStatus = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isMobileApp =
    queryParams.get(APP_URL_QUERY_PARAMS.IS_MOBILE_APP) === 'true';

  return isMobileApp;
};

export const isSodimacBusinessUnit = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tenant = queryParams.get(APP_URL_QUERY_PARAMS.TENANT) || '';
  const country = (tenant.substring(0, 2).toUpperCase() as 'SO' | 'FA') || 'SO';

  return country === SHORT_BUSINESS_UNITS.SO;
};

export const getZones = () => {
  const isMobileApp = getMobileAppStatus();
  const isSodimacBU = isSodimacBusinessUnit();
  let parsedZones = '';
  if (isMobileApp) {
    const queryParams = new URLSearchParams(window.location.search);
    parsedZones = queryParams.get(APP_URL_QUERY_PARAMS.ZONES) || '';
  } else if (isSodimacBU) {
    parsedZones = Cookies.get(SODIMAC_COOKIES.ZONES) || '';
  } else {
    const zones = Cookies.get(FALABELLA_COOKIES.ZONES) || '';
    parsedZones = zones.slice(1, -1).split('"').join('');
  }

  return parsedZones;
};

export const getCurrentAppEnvironment = () => {
  let env = process.env.REACT_APP_ENVIRONMENT as Environments;
  if (ENVS.includes(env) === false) {
    env = 'production';
  }

  return env;
};

export const getCurrentTenant = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tenant = queryParams.get(APP_URL_QUERY_PARAMS.TENANT) || '';

  return tenant.toUpperCase() as Tenants;
};

export const getPriceGroupId = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isSodimacBU = isSodimacBusinessUnit();
  const isMobileApp = getMobileAppStatus();
  const isSOCO = getCurrentTenant() === TENANT_NAMES.SOCO;
  let pgId = '';
  if (isMobileApp) {
    pgId = queryParams.get(APP_URL_QUERY_PARAMS.PG_ID) || '';
  } else if (isSOCO) {
    pgId = queryParams.get(SODIMAC_COOKIES.PG_ID_CO) || '';
  } else if (isSodimacBU) {
    pgId = Cookies.get(SODIMAC_COOKIES.PG_ID) || '';
  } else {
    pgId = Cookies.get(FALABELLA_COOKIES.PG_ID) || '';
  }

  return pgId;
};

export const getPoliticalId = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isMobileApp = getMobileAppStatus();
  let pId = '';
  if (isMobileApp) {
    pId = queryParams.get(APP_URL_QUERY_PARAMS.PID) || '';
  } else {
    pId = Cookies.get(FALABELLA_COOKIES.PID) || '';
  }

  return pId;
};

export const getProductDetailsApiQueryParams = () => {
  const zones = getZones();
  const pgId = getPriceGroupId();
  let queryparams: {
    zones: string;
    pgId: string;
    pId?: string;
  } = {
    zones,
    pgId,
  };
  const isSodimacBU = isSodimacBusinessUnit();
  if (!isSodimacBU) {
    const pId = getPoliticalId();
    queryparams = {
      ...queryparams,
      pId,
    };
  }

  return queryparams;
};

export const isAddToCartAtgEnabled = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isAtgEnabled =
    queryParams.get(APP_URL_QUERY_PARAMS.IS_ATG_ENABLED) === 'true';

  return isAtgEnabled;
};

export const handleButtonsClick = (
  event: React.KeyboardEvent<HTMLDivElement>,
  callback: () => void
) => {
  if (event.key === 'Enter') {
    callback();
  }
};

export const getCurrentBusinessUnit = () => {
  const isSodimacBU = isSodimacBusinessUnit();
  if (isSodimacBU) {
    return BUSINESS_UNITS.SODIMAC;
  } else {
    return BUSINESS_UNITS.FALABELLA;
  }
};

export const getCurrentStore = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const store =
    queryParams.get(APP_URL_QUERY_PARAMS.STORE) || BUSINESS_UNITS.SODIMAC;

  return store;
};

export const getContentsToInclude = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const contentsToInclude =
    queryParams.get(APP_URL_QUERY_PARAMS.CONTENTS_TO_INCLUDE) ||
    defaultContentsToInclude.join(',');

  return contentsToInclude;
};

export const getCsEntryId = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const csEntryId = queryParams.get(APP_URL_QUERY_PARAMS.CS_ENTRY_ID) || '';

  return csEntryId;
};

export const getCartId = () => {
  const cartId = Cookies.get(FALABELLA_COOKIES.CART_ID) || '';

  return cartId;
};

export const getAuthSid = () => {
  const authSid = Cookies.get(FALABELLA_COOKIES.AUTH_SID) || '';

  return authSid;
};

export const isNormalPrice = (priceType: string) => {
  const isSodimacBU = isSodimacBusinessUnit();
  if (isSodimacBU) {
    return priceType === PRICE_TYPES.NORMAL;
  } else {
    return priceType === PRICE_TYPES_FA.NORMAL;
  }
};

export const getPlaceHolderThumbNail = () => THUMBNAIL_PLACEHOLDER;

export const getPlaceholderDesktopMainImage = () =>
  DESKTOP_MAIN_IMAGE_PLACEHOLDER;

export const getPlaceholderMobileMainImage = () =>
  MOBILE_MAIN_IMAGE_PLACEHOLDER;

export const removeSpaces = (str: string) => {
  return str.replace(/\s+/g, '');
};

export const removeSpacesAndLowerCase = (str: string) => {
  if (!str) return '';
  return removeSpaces(str).toLowerCase();
};

export const updateImageURL = ({
  url,
  format,
  width,
}: {
  url: string;
  format: string;
  width: number;
}) => {
  const urlObject = new URL(url);
  urlObject.searchParams.set('format', format);
  urlObject.searchParams.set('width', width.toString());

  return urlObject.toString();
};

export const getLanguage = () => {
  const tenant = getCurrentTenant();
  const { SPANISH, PORTUGUESE } = LANGUAGES;
  if (PORTUGUESE_COUNTRIES.includes(tenant.toLowerCase())) {
    return PORTUGUESE;
  }
  return SPANISH;
};
