export const SHORT_BUSINESS_UNITS = {
  SO: 'SO',
  FA: 'FA',
};
export const HOME_IMPROVEMENT_SERVICE_BASE_URL = {
  [SHORT_BUSINESS_UNITS.SO]: {
    development: 'https://staging.sodimac.cl',
    staging: 'https://staging.sodimac.cl',
    beta: 'https://beta.sodimac.cl',
    production: 'https://www.sodimac.cl',
  },
  // TODO: Add SO urls
  [SHORT_BUSINESS_UNITS.FA]: {
    development: 'https://staging.falabella.com',
    staging: 'https://staging.falabella.com',
    beta: 'https://beta.falabella.com',
    production: 'https://www.falabella.com',
  },
};

export const defaultContentsToInclude = [
  'pages.journeys_page_introduction',
  'pages.journeys_page_timeline',
  'pages.journeys_page_measurement',
  'pages.journeys_page_product_details',
  'pages.journeys_page_addtocart',
];

export type Environments = 'development' | 'staging' | 'beta' | 'production';

export const IMAGE_HOSTS = {
  [SHORT_BUSINESS_UNITS.SO]: 'images.sodimac.com',
  [SHORT_BUSINESS_UNITS.FA]: 'images.falabella.com',
};

export const IMAGE_URLS = {
  CHEVRON_UP: {
    URL: 'https://images.sodimac.com/v3/assets/blt34d59f5b52e53f95/blt38a758dcb9b28761/Chevron-up.svg',
    ALT_TEXT: 'Chevron Up',
  },
  CLOSE_ICON: {
    URL: 'https://images.sodimac.com/v3/assets/blt34d59f5b52e53f95/bltdae2e12ec75cb6b3/cross-icon.svg',
    ALT_TEXT: 'Close Icon',
  },
  SUCCESS_ICON: {
    URL: 'https://images.sodimac.com/v3/assets/blt34d59f5b52e53f95/blt19c6f0b07446c315/succes.svg',
    ALT_TEXT: 'Success Icon',
  },
};

export const FETCH_SESSION_INFO_URL =
  'rest/model/falabella/rest/common/CommonActor/fetchSessionInfo';
export const ATG_CART_URL =
  'rest/model/atg/commerce/order/purchase/CartModifierActor/addItemToBasket';

export const CATALYST_CART_URL = 's/checkout/v1/carts';

const KEYS = {
  BASE_URL: 'BASE_URL',
};

export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  BETA: 'beta',
  PRODUCTION: 'production',
};

export const TENANT_NAMES = {
  SOMX: 'SOMX',
  SOCO: 'SOCO',
  SOAR: 'SOAR',
  SOPE: 'SOPE',
  SOCL: 'SOCL',
  SOUY: 'SOUY',
  SOBR: 'SOBR',
  FACL: 'FACL',
  FAPE: 'FAPE',
  FACO: 'FACO',
};

export const ATG_SITE_IDS = {
  [TENANT_NAMES.SOMX]: 'SMXStore',
  [TENANT_NAMES.SOCO]: 'SOCOStore',
  [TENANT_NAMES.SOAR]: 'SARStore',
  [TENANT_NAMES.SOPE]: 'SPEStore',
  [TENANT_NAMES.SOCL]: 'SCLStore',
  [TENANT_NAMES.SOUY]: 'SUYStore',
  [TENANT_NAMES.SOBR]: 'SBRStore',
};

export const TENANTS = {
  [TENANT_NAMES.SOMX]: {
    [ENVIRONMENTS.DEVELOPMENT]: {
      [KEYS.BASE_URL]: 'https://staging.sodimac.com.mx',
    },
    [ENVIRONMENTS.STAGING]: {
      [KEYS.BASE_URL]: 'https://staging.sodimac.com.mx',
    },
    [ENVIRONMENTS.BETA]: {
      [KEYS.BASE_URL]: 'https://beta.sodimac.com.mx',
    },
    [ENVIRONMENTS.PRODUCTION]: {
      [KEYS.BASE_URL]: 'https://www.sodimac.com.mx',
    },
  },
  [TENANT_NAMES.FACL]: {
    [ENVIRONMENTS.DEVELOPMENT]: {
      [KEYS.BASE_URL]: 'https://staging.falabella.com',
    },
    [ENVIRONMENTS.STAGING]: {
      [KEYS.BASE_URL]: 'https://staging.falabella.com',
    },
    [ENVIRONMENTS.BETA]: {
      [KEYS.BASE_URL]: 'https://beta.falabella.com',
    },
    [ENVIRONMENTS.PRODUCTION]: {
      [KEYS.BASE_URL]: 'https://www.falabella.com',
    },
  },
};

export const PRODUCT_DETAILS_URL = 's/home-improvements/services/product';

export const PRODUCT_DETAILS_API_QUERY_PARAMS = {
  PRODUCT_ID: 'productId',
  VARIANT_ID: 'variantId',
  ZONES: 'zones',
  PGID: 'pgid',
  PID: 'pid',
};

export const CONTENT_STACK_HOSTS = [
  'images.contentstack.io',
  'assets.contentstack.io',
];
export const CONTENT_STACK_IMAGE_HOST = 'images.contentstack.io';

export const DESKTOP_MAIN_IMAGE_PLACEHOLDER =
  'https://images.sodimac.com/v3/assets/blt6df9971ff924b699/blt3b79ed690d9eb3df/66fb9eae48be05c08c15babb/desktop_main_placeholder.png';
export const MOBILE_MAIN_IMAGE_PLACEHOLDER =
  'https://images.sodimac.com/v3/assets/blt6df9971ff924b699/bltd416a2b2384dbd36/66fb9eae7d4d2f05518b5c40/mobile_placeholder.png';
export const THUMBNAIL_PLACEHOLDER =
  'https://images.sodimac.com/v3/assets/blt6df9971ff924b699/bltc606c4a51d060516/66fb9eae504b4b809f982c3e/thumb-placeholder.png';

export const SELECTION_API_PATH = '/s/home-improvements/services/selection';
